import 'particles.js';
const particlesJS = window.particlesJS;

export default function(){
  // Particles JS
  particlesJS.load('particles-js', 'public/particlesjs-config.json', function() {
  });

  particlesJS.load('particles-js2', 'public/particlesjs-config.json', function() {
  });

  // AOS
  AOS.init();

  // Scroll Listener
  const throttled = throttle(scrolledPastHeader, 500);
  window.addEventListener('scroll', throttled);
  
  const name = document.querySelector('#name');
  function scrolledPastHeader(event){
    if (!name.getAttribute('data-theme') && document.documentElement.scrollTop > window.innerHeight) {
      name.setAttribute('data-theme', 'dark');
      return;
    }
    if (name.getAttribute('data-theme') && document.documentElement.scrollTop < window.innerHeight) {
      name.removeAttribute('data-theme');
      return;
    }
  }

}

function throttle(func, wait = 100) {
  let timer = null;
  return function(...args) {
    if (timer === null) {
      timer = setTimeout(() => {
        func.apply(this, args);
        timer = null;
      }, wait); 
    }
  };
}